import React, { Component } from "react";
import renderHTML from 'react-render-html';

class InfoBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.Title,
      description: props.Description,
    };
  }

  render() {
    return (
      <div>
        <div>
          <div className="app-content-infoblock-header">
            {this.state.title}
          </div>
        </div>
        <div>
            {renderHTML(this.state.description)}
        </div>
      </div>
    );
  }
}

export default InfoBlock;
