import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import vote_icon from "./images/vote-icon.png";
import drop_down_icon from "./images/drop-down-arrow.png";
import InfoBlock from "./InfoBlock.js";
import { Link } from "react-router-dom";

class HowTo extends Component {
  render() {
    return (
      <div className="app-content-background">
        <Container>
          <Row className="app-content-row">
            <Col>
              <h1>How to use the online voting page</h1>
            </Col>
          </Row>
        </Container>
        <Container className="app-content">
          <Row className="app-content-row">
            <Col md="12" sm="12">
              <InfoBlock
                Title="Signing in"
                Description="<div>
Please use your own unique encrypted Access Code and Password to sign in.
</div>"
              ></InfoBlock>
            </Col>
          </Row>
          <Row className="app-content-row">
            <Col md="12" sm="12">
              <InfoBlock
                Title="Initial list ranking page"
                Description="<div>
The first page you will see are the candidates listed by initial list ranking order, as determined by the Green Party's internal delegate voting process. If you agree with the order of the initial list ranking, please scroll to the bottom of the page and select the 'Continue' button. You will be asked to 'Submit' your vote.
</div>"
              ></InfoBlock>
            </Col>
          </Row>
          <Row className="app-content-row">
            <Col md="12" sm="12">
              <InfoBlock
                Title="Candidate information"
                Description=""
              ></InfoBlock>
              <div>
                You can click on the{" "}
                <img src={drop_down_icon} alt="drop down" /> arrow icon beside
                the name of each candidate to see more information about that
                candidate.
              </div>
            </Col>
          </Row>
          <div className="app-horizontal-ruling"></div>
          <Row className="app-content-row">
            <Col md="12" sm="12">
              <InfoBlock
                Title="Ranking your own preferences"
                Description="<div>
Should you wish to rank the candidates according to your own preferences please click the 'Clear Selection' button at the bottom of the page. This will remove all initial preferences, however, the order of the candidates' names will remain the same.
</div>"
              ></InfoBlock>
            </Col>
          </Row>
          <Row className="app-content-row">
            <Col md="12" sm="12">
              <InfoBlock
                Title="Voting process"
                Description="<div>
There are 32 candidates listed in an initial list ranking order in a single column. You will need to scroll down to see all candidate names.
In order to vote, select the box next to the candidate you want to be first on the party list. This will enter a 1 in the box. Then select the box next to the candidate you want to be second on the party list. This will enter a 2 in the box, and so on.
<br/>
You can rank as many or as few candidates as you like. When you have finished ranking, select the 'Continue' button. In the summary, the candidate list will appear in the numerical order of your preferences.
                    <br/><br/>
                    <strong>Changing your preferences</strong>
                    <br/>
To change your selection you can either
<ul><li>Click the 'clear selection' button – this will remove all preferences.
</li><li>Click in the box beside your last ranked candidate – this will remove your last preference.
</li><li>Click the 'start again' button – this will revert the numbering of candidates back to the initial list ranking.
</li></ul>
                    <br/><br/>
                    <strong>Submitting your vote</strong>
                    <br/>
Before you submit your vote you will be presented with a vote summary in order to confirm your preferences. Once you are satisfied that the ranking of candidates reflects your chosen preferences, select the 'Submit my vote' button to continue or the 'Change my vote' button to return to the voting screen.
                    <br/><br/>
                    <span style='color:red'><strong>Submitting your vote is <u>final</u> and cannot be undone.</strong></span>
                    </div>"
              ></InfoBlock>
              <br/>
              Finished reading? <Link to='/'>Click here</Link> to return to the
              home page for further instructions or click the 'CLICK HERE TO VOTE' button
              below to start voting.
            </Col>
          </Row>
          <div className="app-horizontal-ruling"></div>
          <Row className="app-content-row">
            <Col className="app-centered-button">
              <Button
                size="large"
                href="https://www.voteonline.co.nz/green2023"
                target="_blank"
                className="app-button"
              >
                CLICK HERE TO VOTE
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HowTo;
