import React, { Component } from "react";

import vote_icon from "./images/vote-icon.png";
import { Container, Row, Col, Button } from "reactstrap";
import InfoBlock from "./InfoBlock.js";
import { Link } from "react-router-dom";

class Home extends Component {
  render() {
    return (
      <div>
        <div className="app-content-background">
          <Container>
            <Row className="app-content-row">
              <Col>
                <h1>
                  Welcome to the Green Party of Aotearoa New Zealand General
                  Election Candidate List Ranking 2023
                </h1>
              </Col>
            </Row>
          </Container>
          <Container className="app-content">
            <Row className="app-content-row">
              <Col className="text-center">
                <div className="app-instructions">
                  Please read these instructions carefully, then select the 'CLICK HERE TO VOTE' button to log in and start voting.
                </div>
              </Col>
            </Row>
            <div className="app-horizontal-ruling"></div>
            <Row className="app-content-row">
              <Col md="12" sm="12">
                <InfoBlock
                  Title="Important Information"
                  Description="<div>
Please note that, unlike previous years, this year you will be given the option to select the initial list ranking as determined by the Green Party's internal delegate voting process. Should you wish to make your own selection you can. Please follow the instructions to rank your list.
<br/><br/>
<em>Please note that there are 32 candidates to be ranked in this process. You can rank as many or as few as you like. Given the number of candidates, this process may take some time and <strong>the voting system will log you out after 20 minutes of inactivity for security reasons</strong> (this timer will reset every time you make a selection on the page, and you will get a warning beforehand with the ability to extend your session). You can read further information about the candidates and the Party’s balancing criteria on the members’ site (log in required) <a href='https://members.greens.org.nz/post/members-ballot-general-election-2023'>https://members.greens.org.nz/post/members-ballot-general-election-2023</a>. It is advisable to read this information and compile your ranking before proceeding to the voting form to enter your choices.</em>
<br/><br/>
<em>Please note that for security reasons, your <strong>progress will not be saved</strong>. You will be required to complete your ranking in one session.</em>
                                  </div>"
                ></InfoBlock>
              </Col>
            </Row>
            <div className="app-horizontal-ruling"></div>
            <Row className="app-content-row">
              <Col md="12" sm="12">
                <InfoBlock
                  Title="Close of voting"
                  Description="<div>
Voting closes at <strong>12:00 pm on 12 May 2023</strong> and you will need to submit your vote before then for it to be counted.
</div>"
                ></InfoBlock>
              </Col>
            </Row>
            <div className="app-horizontal-ruling"></div>
            <Row className="app-content-row">
              <Col md="9" sm="12">
                <InfoBlock
                  Title="Accessibility"
                  Description="<div>
VoteOnline offers support for vision impaired users and has been independently audited for conformity against the <a href='https://www.w3.org/WAI/standards-guidelines/wcag/' target='_blank' title='WCAG 2.0 Guidelines Link'>WCAG 2.0 guidelines</a> at an AA standard - meeting the 38 Success Criteria outlined in WCAG 2.0 Level AA.
<br/><br/>
If you need assistance with voting <a href='https://www.nzrelay.co.nz/' target='_blank' title='NZ Relay Link'>NZ Relay</a> provides a number of services to help.
                                  </div>"
                ></InfoBlock>
              </Col>
            </Row>
            <div className="app-horizontal-ruling"></div>
            <Row className="app-content-row">
              <Col md="12" sm="12">
                <InfoBlock
                  Title="Vote securely"
                  Description="<div>
You must vote using your own Access Code and Password. Don't let anyone else view these details, and make sure that you vote privately and use a secure internet connection. Please keep your Access Code and Password to yourself – do not share with others.
<br/>
<span style='color:red'><strong>Submitting your vote is <u>final</u> and cannot be undone.</strong></span>
<br/><br/>
                                  </div>"
                ></InfoBlock>
<Link to='/how-to-vote' title='How To Vote Link'>Click here</Link> for further instructions on how to vote, or select 'CLICK HERE TO VOTE' to get started.
              </Col>
            </Row>
            <div className="app-horizontal-ruling"></div>
            <Row className="app-content-row">
              <Col className="app-centered-button">
                <Button
                  size="large"
                  href="https://www.voteonline.co.nz/green2023"
                  target="_blank"
                  className="app-button"
                >
                  CLICK HERE TO VOTE
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Home;
