import React, { Component } from 'react';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
} from 'reactstrap';
import logo from './images/green-logo-white.png';
//import logo from './images/Green-Logo_Mid_2018.png';
import './App.css';
import Home from "./Home";
import HowTo from "./HowTo";

class Main extends Component {
  constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
          isOpen: false
      };
  }
  toggle() {
      this.setState({
          isOpen: !this.state.isOpen
      });
  }
  render() {
    return (
      <HashRouter>
        <Navbar color="inverse app-navbar" light expand="md">
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem className="app-nav-item-home app-nav-link">
                         <NavLink to="/">HOME</NavLink>
                    </NavItem>
                    <NavItem className="app-nav-item-how app-nav-link">
                        <NavLink to="/how-to-vote">HOW TO VOTE</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
        <table className="app-header">
          <tr className="app-header-overlay">
            <td>
                <img src={logo} className="app-logo" alt="green-logo" />
            </td>
          </tr>
        </table>
        <div className="app-divider"></div>
        <div className="content">
          <div className="app">
            <Route exact path="/" component={Home}/>
            <Route path="/how-to-vote" component={HowTo}/>
          </div>
        </div>
        <footer className="app-footer"></footer>
      </HashRouter>
    );
  }
}

export default Main;
